import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
import Shield from "../Assets/Shield.png";
import Charts from "../Assets/Chart.png";
import Btc from "../Assets/BTC.png";
import Zodlex from "../Assets/Zodlex.png";
// import coingo from "../Assets/coin_go_GIF.gif";
import Rectangle from "../Assets/rectangle.png";
import Box from "../Assets/box.png";
import Wallets from "../Assets/wallet.png";
import battery from "../Assets/battery.png";
import smallrect from "../Assets/smallrect.png";
import smallround from "../Assets/roundcorner.png";
import machine from "../Assets/machine_gif.gif";
import { Link } from "react-router-dom";

export default function Homepage() {


    return (
        <>

            <section id="stakesection" className="allsection fire">
            <div className="headers">
           {/* <Header/> */}
           </div>
                <Container className="ff custom_container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mt-3">
                        <img src={machine} className="img-fluid machines" alt="circle" />
                        </div>
                        <div className="col-md-6 mt-3 zodlexpad">
                            <h1>Zodlex Stake Platform</h1>
                            <p className="subpara mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>                           
                            <div className="d-flex align-items-center lockedd mb-3">
                              <img src={smallround} className="img-fluid smallround" alt="circle" />
                              <div className="ms-3">
                                <h3 className="mb-0">Staking</h3>
                                <p className="mb-0">Low fees and instant transaction confirmation.</p>
                              </div>
                           </div> 
                           <div className="d-flex lockedd">
                              <img src={smallrect} className="img-fluid smallrect" alt="circle" />
                              <div className="ms-3">
                                <p className="mb-0">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                              </div>
                           </div> 
                          <Link to="http://43.204.99.101:9102/pools/" target="_blank"> <Button variant="primary" className="mt-4">Learn more</Button></Link>
                        </div>
                    </div>
                </Container>
            </section>


        </>
    );
}