import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button, Nav } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
import Shield from "../Assets/Shield.png";
import Charts from "../Assets/Chart.png";
import Btc from "../Assets/BTC.png";
import Zodlex from "../Assets/Zodlex.png";
import vpipe from "../Assets/pipes_GIF.gif";
import { Link } from "react-router-dom";

export default function Homepage() {


    return (
        <>

            <section id="addpoolsection" className="allsection navsection fire">
            <div className="headers">
           {/* <Header/> */}
           </div>
                <img src={vpipe} className="img-fluid vpipe" alt="circle" />
                <img src={Zodlex} className="img-fluid zodlex" alt="circle" />
                <Container className="ff custom_container">
                    <div className="row pt-4">
                        <div className="col-md-10 kot zodlexpad">                                                  
                            {/* <h1 class="outliness">Zodlex</h1> */}
                            <div className="row justify-content-end">
                                <div className="col-md-9 col-lg-7 tri">
                                <div className="sidenav">
                                        <ul>
                                        <li><Nav.Link href="#locked">Locked Stacking</Nav.Link></li>
                                            <li><Nav.Link href="#farm">Farms</Nav.Link></li>
                                            <li><Nav.Link href="#stake">Stake</Nav.Link></li>
                                            <li><Nav.Link href="#swap">Swap</Nav.Link></li>
                                        </ul>
                                    </div>
                                    <div className="pool listed mt-3">
                                        <h2>Nunc non venenatis tellus</h2>
                                        <h1>Pulvinar pool</h1>
                                        <p className="subpara">Donec ultrices enim id risus bibendum, nec finibus urna tempus. Nunc gravida porta egestas. Mauris dapibus purus quis ante blandit dictum. Morbi nibh sapien, semper sit amet egestas vitae, semper ut ante. Fusce aliquet, ipsum a rhoncus fermentum, eros nisi feugiat dui, vel hendrerit mauris magna at magna. Nullam aliquet enim a libero accumsan, quis pulvinar ante iaculis.
                                            <br></br><br></br>
                                            Aliquam eu lacinia sem. Vivamus porta tincidunt convallis. Curabitur consequat placerat eros a placerat. Praesent semper orci quis mauris tincidunt lobortis. Nam ultricies est id libero congue.                                            
                                            </p>
                                            <Link to="http://43.204.99.101:9103/#/pool/" target="_blank"><Button variant="primary" className="mt-2 mb-2">Explore more</Button></Link>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>

                </Container>



            </section>


        </>
    );
}