import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import walletcoin from "../Assets/walletcoin.gif";

export default function Headermodal(props) {
    const [referralmodal, setReferralmodal] = useState(true)
    return (
        <>

            <div>
                <Modal
                className="allmodals"
                    onHide={props.onDismiss}
                    show={referralmodal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    
                >
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body>
                     <img src={walletcoin} className="img-fluid walletcoin waco mb-3" alt="circle" />
                        <Nav.Link href="/" onClick={props.onDismiss}>Home</Nav.Link>
                        <Nav.Link href="#swap" onClick={props.onDismiss}>Swap</Nav.Link>
                        <Nav.Link href="#addpool" onClick={props.onDismiss}>Add Pool</Nav.Link>
                        <Nav.Link href="#farm" onClick={props.onDismiss}>Farms</Nav.Link>
                        <Nav.Link href="#stake" onClick={props.onDismiss}>Stake</Nav.Link>
                        <Nav.Link href="#faq" onClick={props.onDismiss}>Faq</Nav.Link>
                        <Nav.Link href="#locked" onClick={props.onDismiss}>Locked Stacking</Nav.Link>
                    </Modal.Body>
                </Modal>
            </div>

        </>
    );
}