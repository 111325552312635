import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
// import crane from "../Assets/crane.png";
// import bitz from "../Assets/bitz.webp";
import troll2 from "../Assets/coins-trans.gif";

export default function Homepage() {

    return (
        <>

            <section id="swaptradesection" className="allsection fire">
                <div className="headers">
                    {/* <Header /> */}
                </div>
                <Container className="ff custom_container">
                    <div className="coins">
                        <h1 className="swapheading mb-2">Start trading with ZOD</h1>
                        <h1 className="swapheading hd2 mb-2">Swap and Enjoy</h1>
                    </div>
                    <p className="mt-3 swap_p">Donec ultrices enim id risus bibendum, nec finibus urna tempus. Nunc gravida porta egestas. Mauris dapibus purus quis ante blandit dictum. Morbi nibh sapien, semper sit amet egestas vitae, semper ut ante. </p>
                    {/* <svg viewBox="0 0 450 50">
                        <text y="50">500+ Coin support</text>
                    </svg> */}
                    <h1 class="outlines mb-0">500+ Coin support</h1>
                    <div className="text-center mtop pt-3"><img src={troll2} className="img-fluid coingif" alt="circle" /></div>

                </Container>
            </section>

        </>
    );
}