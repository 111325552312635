import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './Home.css';
import Fullpagess from './Pages/Fullpage'

export default function App() {
  return (
    <>
    
    <BrowserRouter >
      <Routes>
      <Route path="/" element={<Fullpagess />} />       
      </Routes>
     </BrowserRouter>
    
    </>

);
}
