import React, { useRef, useEffect, useState } from "react";
import { Button, Container, Row, Nav, NavDropdown, Navbar, Modal } from 'react-bootstrap';
import logos from '../Assets/logo.png';
import menus from '../Assets/allmenu.png'
import $ from "jquery";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import Referralmodal from "../Components/Headermodal"

export default function Header(props) {
    const [referralmodal, setReferralmodal] = useState(false)

    return (
        <>
        {referralmodal && <Referralmodal onDismiss={() => setReferralmodal(false)} />}
            <div className="desktopheader">
                <Navbar expand="lg">
                    <Container className="custom_container">
                        <Navbar.Brand href="/">
                            <img className="headerlogo" src={logos} alt="jack" />

                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="#swap">Swap</Nav.Link>
                                <Nav.Link href="#addpool">Add Pool</Nav.Link>
                                <Nav.Link href="#farm">Farms</Nav.Link>
                                <Nav.Link href="#stake">Stake</Nav.Link>
                                <Nav.Link href="#faq">Faq</Nav.Link>
                                <Nav.Link href="#wallet">Wallet</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>

            <div className="mobileheader">
                <div className="headermobile d-flex align-items-center justify-content-between">
               <Link to="/"><img className="headerlogo" src={logos} alt="jack" /></Link>
                   <img className="img-fluid menumobile" onClick={() => setReferralmodal(true)} src={menus} alt="jack" /> 
                </div>
            </div>

          

        </>
    );
}