import React from "react";
import Homepage from "./Homepage";
import Homepage1 from "./Homepage1";
import Homepage2 from "./Homepage2";
import Homepage3 from "./Homepage3";
import Homepage4 from "./Homepage4";
import Homepage5 from "./Homepage5";
import Homepage6 from "./Homepage6";
import Homepage7 from "./Homepage7";
import Homepage8 from "./Homepage8";
import Header from "../Layouts/Header";

export default function Apps() {
    return (
      <>
      {/* <Header/> */}
       <div className="containerss">
       <div className="headers"><Header/></div>
        <section className="one sectionlist">
       
        <Homepage/>
        </section>
        <section className="two sectionlist" id="swap">
        {/* <div className="headers"><Header/></div> */}
         <Homepage1/>
        </section>
        <section className="three sectionlist">
        {/* <div className="headers"><Header/></div> */}
         <Homepage2/>
        </section>
        <section className="four sectionlist">
        {/* <div className="headers"><Header/></div> */}
         <Homepage3/>
        </section>
        <section className="five sectionlist" id="addpool">
        {/* <div className="headers"><Header/></div> */}
         <Homepage4/>
        </section>
        <section className="six sectionlist" id="farm">
        {/* <div className="headers"><Header/></div> */}
         <Homepage5/>
        </section>
        <section className="seven sectionlist" id="stake">
        {/* <div className="headers"><Header/></div> */}
         <Homepage6/>
        </section>
        <section className="eight sectionlist" id="faq">
        {/* <div className="headers"><Header/></div> */}
         <Homepage7/>
        </section>
        <section className="nine sectionlist" id="wallet">
        {/* <div className="headers"><Header/></div> */}
         <Homepage8/>
        </section>
        </div>

      </>
    );
  }
  