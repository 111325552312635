import React, { useEffect, useRef, useState } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
// import crane from "../Assets/crane.png";
import troll from "../Assets/crane_gif.gif";
import trollimg from "../Assets/Framess.png";
import downslide from "../Assets/downslide.png";
import upslide from "../Assets/upslide.png";
import { Link } from "react-router-dom";
// import bitz from "../Assets/bitz.webp";

export default function Homepage() {

   const[initialimg, setinitialimg] = useState(true);

   useEffect(() => {
    setTimeout(() => {
      setinitialimg(false); // count is 0 here
    }, 3400);
          
   }, [])

    return (
        <>


      <section id="bannersection" className="allsection fire">
           {/* <Header className="mb-5"/> */}
           <div className="headers">
           {/* <Header/> */}
           </div>
        <Container className="custom_container">
          <div className="row pt-4 align-items-center">
            <div className="col-md-6 mt-3 zodlexpad">
              <h1 className="heading mb-3">ZODLEX DEFI</h1>
              <p className="subheading mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Sed at viverra arcu, sit amet vestibulum lectus. Proin eget malesuada tortor.</p>
              <Link to="http://43.204.99.101:9102" target="_blank"><Button variant="primary" className="mt-3">Explore more</Button></Link>
              <img src={ball} className="ball" alt="circle"/>
              <img src={circle} className="circle" alt="circle"/>
            </div>
            <div className="col-md-6 mt-3">
              {initialimg?
              <div className="allpos cursors" onClick={()=>setinitialimg(false)}>
              <img src={trollimg}  className="img-fluid fullimg" alt="circle"/>
              <img src={downslide} className="img-fluid downslide" alt="circle"/>
              <p className="press">PRESS TO START</p>
              </div>:  
              <div className="allpos">
              <img src={troll} className="img-fluid fullimg" alt="circle"/>
              <img src={upslide} className="img-fluid downslide" alt="circle"/>
              </div>
            }
                   
            </div>
          </div>
        </Container>
      </section>


</>
  );
}