import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
import Shield from "../Assets/Shield.png";
import Charts from "../Assets/Chart.png";
import Btc from "../Assets/BTC.png";
// import bitz from "../Assets/bitz.webp";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import troll3 from "../Assets/pipe1.gif_trim.gif";
import { Link } from "react-router-dom";

export default function Homepage() {

    // const options = {
    //     margin: 30,
    //     responsiveClass: true,
    //     nav: false,
    //     dots: true,
    //     autoplay: false,
    //     // navText: ["Prev", "Next"],
    //     smartSpeed: 1000,
    //     responsive: {
    //         0: {
    //             items: 1,
    //         },
    //         400: {
    //             items: 1,
    //         },
    //         600: {
    //             items: 1,
    //         },
    //         700: {
    //             items: 1,
    //         },
    //         1000: {
    //             items: 1,

    //         }
    //     },
    // };

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
        <>

            <section id="swapcarouselsection" className="allsection fire">
            <div className="headers">
           {/* <Header/> */}
           </div>
                <Container className="ff custom_container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mt-3 zodlexpad">
                            <h1 className="swapheading mb-3">Start trading with ZOD</h1>
                            <h1 className="swapheading hd2 mb-5">Swap and Enjoy</h1>
                            {/* <OwlCarousel className='owl-theme mt-5' {...options}>
                                <div class='item'>
                                    <div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2"><img src={Shield} className="big" alt="circle" /></div>
                                            <div className="col-md-10"><h2>Big Companies Joined</h2><p className="mb-0">Low fees and instant transaction confirmation.</p></div>
                                        </div>
                                        <p className="mt-3">Set to operate a next-gen decentralized exchange, swapping digital assets from across the Interchain, with very low fees and instant transaction confirmation.</p>
                                    </div>
                                </div>
                                <div class='item'>
                                <div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2"><img src={Charts} className="big" alt="circle" /></div>
                                            <div className="col-md-10"><h2>Big Companies Joined</h2><p className="mb-0">Low fees and instant transaction confirmation.</p></div>
                                        </div>
                                        <p className="mt-3">Set to operate a next-gen decentralized exchange, swapping digital assets from across the Interchain, with very low fees and instant transaction confirmation.</p>
                                    </div>
                                </div>
                                <div class='item'>
                                <div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2"><img src={Btc} className="big" alt="circle" /></div>
                                            <div className="col-md-10"><h2>Big Companies Joined</h2><p className="mb-0">Low fees and instant transaction confirmation.</p></div>
                                        </div>
                                        <p className="mt-3">Set to operate a next-gen decentralized exchange, swapping digital assets from across the Interchain, with very low fees and instant transaction confirmation.</p>
                                    </div>
                                </div>
                                
                            </OwlCarousel> */}
                            <Slider {...settings}>
                                <div>
                                <div className="items">
                                        <div className="row align-items-center">
                                            <div className="col-md-2 col-4"><img src={Shield} className="big img-fluid" alt="circle" /></div>
                                            <div className="col-md-10 col-8"><h2>Big Companies Joined</h2><p className="mb-0">Low fees and instant transaction confirmation.</p></div>
                                        </div>
                                        <p className="mt-3">Set to operate a next-gen decentralized exchange, swapping digital assets from across the Interchain, with very low fees and instant transaction confirmation.</p>
                                    </div>
                                </div>
                                <div>
                                <div className="items">
                                        <div className="row align-items-center">
                                            <div className="col-md-2 col-4"><img src={Charts} className="big img-fluid" alt="circle" /></div>
                                            <div className="col-md-10 col-8"><h2>Big Companies Joined</h2><p className="mb-0">Low fees and instant transaction confirmation.</p></div>
                                        </div>
                                        <p className="mt-3">Set to operate a next-gen decentralized exchange, swapping digital assets from across the Interchain, with very low fees and instant transaction confirmation.</p>
                                    </div>
                                </div>
                                <div>
                                <div className="items">
                                        <div className="row align-items-center">
                                            <div className="col-md-2 col-4"><img src={Btc} className="big img-fluid" alt="circle" /></div>
                                            <div className="col-md-10 col-8"><h2>Big Companies Joined</h2><p className="mb-0">Low fees and instant transaction confirmation.</p></div>
                                        </div>
                                        <p className="mt-3">Set to operate a next-gen decentralized exchange, swapping digital assets from across the Interchain, with very low fees and instant transaction confirmation.</p>
                                    </div>
                                </div>
                               
                            </Slider>

                            <Link to="http://43.204.99.101:9103/#/swap/" target="_blank"><Button variant="primary" className="mt-4">Explore More</Button></Link>

                        </div>
                        <div className="col-md-6 mt-3">
                        <img src={troll3} className="img-fluid  pipes" alt="circle" />
                        </div>
                    </div>
                </Container>
            </section>


        </>
    );
}