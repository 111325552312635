import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
// import crane from "../Assets/crane.png";
// import bitz from "../Assets/bitz.webp";
import troll1 from "../Assets/machine2_gif.gif";
import diamond from "../Assets/shape31.png";

export default function Homepage() {

    return (
        <>

            <section id="swapsection" className="allsection fire">
            <div className="headers">
           {/* <Header/> */}
           </div>
                <Container className="custom_container">
                    <div className="row align-items-centers">
                        <div className="col-md-6 mt-3 padding adjust zodlexpad">
                            <h1 className="swapheading mb-2">Start trading with ZOD</h1>
                            <h1 className="swapheading hd2 mb-2">Swap and Enjoy !</h1>
                            <p className="subheading mt-2 mb-2">Donec ultrices enim id risus bibendum, nec finibus urna tempus. Nunc gravida porta egestas. Mauris dapibus purus quis ante blandit dictum. Morbi nibh sapien, semper sit amet egestas vitae, semper ut ante. Fusce aliquet, ipsum a rhoncus fermentum, eros nisi feugiat dui, vel hendrerit mauris magna at magna.
                            </p>
                            <div className="row pt-2 align-items-center secgradient">
                               <div className="col-md-6 col-6 col-sm-6 mt-3"><div className="whiteborder text-center"><h1>32k</h1><p className="mb-0">People have Joined</p></div></div>
                               <div className="col-md-6 col-6 col-sm-6 mt-3"><div className="whiteborder text-center"><h1>112+</h1><p className="mb-0">VVIP People have Joined</p></div></div>
                               <div className="col-md-6 col-6 col-sm-6 mt-3"><div className="whiteborder text-center"><h1>81+</h1><p className="mb-0">Big Companies Joined</p></div></div>
                               <div className="col-md-6 col-6 col-sm-6 mt-3"><div className="whiteborder text-center"><h1>200+</h1><p className="mb-0">Big Companies Joined</p></div></div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3 text-center">
                            <img src={troll1} className="img-fluid  coinslip" alt="circle" />
                            <img src={diamond} className="diamond" alt="circle" />
                        </div>
                    </div>
                </Container>
            </section>


        </>
    );
}