import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button, Accordion } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import Particles from "../Components/Particles"
import { Link } from "react-router-dom";
import twitter from "../Assets/Group 501.svg";
import facebook from "../Assets/Group 500.svg";
import insta from "../Assets/Group 499.svg";
import walletcoin from "../Assets/coin9 (1).gif";

export default function Homepage() {


    useEffect(() => {

          
    }, [])

    return (
        <>

            <section id="footersection" className="allsection">
            <div className="headers">
           {/* <Header/> */}
           </div>
                <Container className="ff custom_container">
                   <Particles/>
                    <div className="row align-items-center pt-4">
                        <div className="col-md-6 text-center zodlexpad mt-3">
                            <div className="walletposition">
                            <div>  <img src={walletcoin} className="img-fluid walletcoin mb-3" alt="circle" /></div>
                            <div><Link to="http://43.204.99.101:9102" target="_blank"><Button variant="secondary" className="mt-3">Connect Wallet</Button></Link></div>  
                            </div>
                        </div>
                        <div className="col-md-6 mt-3 zodlexpad">
                           <h1 className="footerh1 mb-4">What’s keeping you<br></br> from Joining us!</h1>    
                           <p className="footerp">Donec ultrices enim id risus bibendum, nec finibus urna tempus. Nunc gravida porta egestas. Mauris dapibus purus quis ante blandit dictum. Morbi nibh sapien, semper sit amet egestas vitae, semper ut ante. Fusce aliquet, ipsum a rhoncus fermentum, eros nisi feugiat dui, vel hendrerit mauris magna at magna. Pellentesque turpis est.</p> 
                         <div className="pt-3">
                            <Link to="https://twitter.com/" target="_blank"> <img src={twitter} className="img-fluid" alt="circle" /></Link>
                            <Link to="https://www.facebook.com/" target="_blank"> <img src={facebook} className="img-fluid ms-2" alt="circle" /></Link>
                            <Link to="https://www.instagram.com/" target="_blank"> <img src={insta} className="img-fluid ms-2" alt="circle" /></Link>
                         </div>
                        </div>
                        <div className="col-md-12">
                            <h5 className="copyright">Copyright @2023. All rights reserved</h5>
                        </div>
                    </div>
                </Container>
               
            </section>
         
        </>
    );
}