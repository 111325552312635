import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button, Accordion, Nav } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
import Shield from "../Assets/Shield.png";
import Charts from "../Assets/Chart.png";
import Btc from "../Assets/BTC.png";
import Zodlex from "../Assets/Zodlex.png";
import viopipe from "../Assets/pipe3_diffusion.gif";

export default function Homepage() {


    return (
        <>

            <section id="faqsection" className="allsection navsection fire">
            <div className="headers">
           {/* <Header/> */}
           </div>
              {/* <img src={viopipe} className="img-fluid violentpipe" alt="circle" /> */}
                <Container className="ff custom_container">
                    <div className="row pt-4">
                    <div className="col-md-3 pt-3 kots">
                    <img src={viopipe} className="img-fluid violentpipes" alt="circle" />
                        </div>
                        <div className="col-md-9 pt-3 kot zodlexpad">
                            <div className="row justify-content-end">
                                <div className="col-md-9 col-lg-7 tri">
                                    <h1 className="pb-3">Frequently Asked Questions</h1>
                                    <div className="sidenav mb-3">
                                        <ul>
                                            <li><Nav.Link href="#locked">Locked Stacking</Nav.Link></li>
                                            <li><Nav.Link href="#farm">Farms</Nav.Link></li>
                                            <li><Nav.Link href="#stake">Stake</Nav.Link></li>
                                            <li><Nav.Link href="#swap">Swap</Nav.Link></li>
                                        </ul>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Contrary to popular belief, Lorem Ipsum is not text?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>It is a long established fact that a reader will be distracted?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Contrary to popular belief, Lorem Ipsum is not text?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>It is a long established fact that a reader will be distracted?</Accordion.Header>
                                            <Accordion.Body>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat.
                                            </Accordion.Body>
                                        </Accordion.Item>                                   
                                    </Accordion>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>


        </>
    );
}