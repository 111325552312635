import React, { useEffect, useRef } from "react";
import Header from "../Layouts/Header";
import logos from '../Assets/logo.png';
import { Container, Button } from "react-bootstrap";
import circle from "../Assets/circle1.png";
import ball from "../Assets/ball.png";
import Shield from "../Assets/Shield.png";
import Charts from "../Assets/Chart.png";
import Btc from "../Assets/BTC.png";
import Zodlex from "../Assets/Zodlex.png";
import coingo from "../Assets/farm_gif1 (1).gif";
import Rectangle from "../Assets/rectangle.png";
import Box from "../Assets/box.png";
import Wallets from "../Assets/wallet.png";
import battery from "../Assets/battery.png";
import waterwave    from "../Assets/waterwave.gif";
import { Link } from "react-router-dom";

export default function Homepage() {


    return (
        <>

            <section id="farmssection" className="allsection fire">
            <div className="headers">
           {/* <Header/> */}
           </div>
           {/* <div class="header">
                    <div class="inner-header flex">
                    </div>
                    <div>
                        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g class="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                </div> */}
                <img src={waterwave} className="img-fluid waterwave" alt="circle" />
                <Container className="ff custom_container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mt-3">
                            <img src={coingo} className="img-fluid coingoo" alt="circle" />
                        </div>
                        <div className="col-md-6 mt-3 zodlexpads">
                            <div className="row align-items-center">
                            <div className="col-md-2 col-3 col-sm-3"> <img src={Rectangle} className="img-fluid" alt="circle" /></div>
                             <div className="col-md-10 col-9 col-sm-9"><h1>Farm<br></br>Mauris<br></br>scelerisque</h1></div>
                            </div>
                            <p className="subpara">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <ul>
                                <li><img src={Box} className="img-fluid box" alt="circle" /> <span className="mx-2">Contrary to popular belief, Lorem Ipsum</span></li>
                                <li><img src={Wallets} className="img-fluid box" alt="circle" /> <span className="mx-2">Contrary to popular belief, Lorem Ipsum</span></li>
                                <li><img src={battery} className="img-fluid box" alt="circle" /> <span className="mx-2">Contrary to popular belief, Lorem Ipsum</span></li>
                            </ul>
                            <Link to="http://43.204.99.101:9102/farms/" target="_blank"><Button variant="primary" className="mt-2 mb-2">Explore more</Button></Link>
                        </div>
                    </div>
                </Container>
            </section>


        </>
    );
}